body {
  margin: 0;
}

.pointer {
  cursor: pointer;
  text-decoration: none;
}

.freelancer-list {
  max-width: auto;
  margin: 0 0 8px;
}
@media (max-width: 450px) {
  .freelancer-list {
    max-width: 250px;
    margin: 0 auto 8px;
  }
}
@media (max-width: 320px) {
  .xs-hidden {
    display: none;
  }
}
.box-wrap > .box {
  margin-bottom: calc(0.5rem);
  flex: 1 0 auto;
  position: relative;
}
.box-wrap > .box + .box {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.box:after {
  content: '';
  float: left;
  display: block;
  padding-top: 100%;
}
.box .inner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.header-icon-colors {
  --header-icon-color-1: #333;
  --header-icon-color-2: #333;
  --header-icon-color-3: #333;
}

.header-icon-colors path {
  transition: fill 250ms;
}

.header-icon-colors:hover {
  --header-icon-color-1: #f06450;
  --header-icon-color-2: #49b592;
  --header-icon-color-3: #e8d53b;
}

.shadow {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.4);
}

.box-shadow-hover {
  transition: box-shadow 250ms;
  box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0);
}

.box-shadow-hover:hover {
  box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.4);
}

.opacity-1 {
  opacity: 1;
  transition: opacity 200ms;
}

.opacity-0 {
  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms;
}

@media (any-hover: hover) {
  .show-on-hover-trigger .show-on-hover {
    opacity: 0;
    transition: opacity 250ms;
  }

  .show-on-hover-trigger:hover .show-on-hover {
    opacity: 1;
  }
}

.hide {
  display: none;
}

.hard-shadow {
  box-shadow: 4px 4px 1px 0px rgba(0, 0, 0, 0.1);
}

.MuiListItem-button:hover {
  background-color: #ddd !important;
}
.clickthrough {
  pointer-events: none;
}
.clickthrough * {
  pointer-events: auto;
}

.pill-button {
  display: flex;
  align-items: center;
  padding: 5px 14px;
  border-radius: 20px;
  border: 1px solid #ddd;
  height: 30px;
  box-sizing: border-box;
  transition: all 200ms;
}

.pill-button.small {
  padding: 2px 10px;
  border-color: #1d1d1d;
}
.pill-button.small > p {
  font-size: 12px;
  line-height: 15px;
  color: #1d1d1d;
}

.pill-button > p {
  font-size: 14px;
  line-height: 18px;
  color: #696969;
  transition: all 200ms;
}

/* .pill-button:hover {
  background-color: #f1f1f1;
  border-color: #f1f1f1;
} */

.pill-button:hover > p {
  text-decoration: underline;
  color: #1d1d1d;
}

.pill-button .pill-count {
  background-color: #f1f1f1;
  padding: 0px 8px;
  border-radius: 15px;
  margin-left: 8px;
  transition: all 200ms;
}

.pill-button .pill-count > p {
  font-size: 12px;
  line-height: 22px;
  transition: all 200ms;
}

.pill-button:hover .pill-count {
  background-color: #1d1d1d;
}
.pill-button:hover .pill-count > p {
  color: white;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.15) !important;
}

.website-item .MuiBox-root {
  flex: 1;
}

.MuiMenuItem-root {
  min-height: 38px !important;
}

body .MuiFilledInput-multiline.MuiFilledInput-marginDense {
  padding-top: 0;
  padding-bottom: 0;
}

.no-margin p {
  margin: 0;
}

.package ul {
  list-style: none;
}
.package ul li::before {
  content: '\2022';
  color: #31b87e;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
