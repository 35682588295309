.month-calendar {
  width: 100% !important;
  border: none;
}
.month-calendar button {
  appearance: none;
  background: none;
  outline: none;
  border: 0;
}

.month-calendar .react-calendar__navigation__label {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  color: black;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  font-weight: 600;
}

.month-calendar .react-calendar__navigation__prev2-button,
.month-calendar .react-calendar__navigation__next2-button {
  display: none;
}

/* day of the week label */
.month-calendar .react-calendar__month-view__weekdays__weekday {
  text-align: right;
  margin: 0;
  margin-top: 30px;
  padding-right: 8px;
  padding-bottom: 8px;
}
.month-calendar abbr[title] {
  text-decoration: none;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #696969;
}
.month-calendar .react-calendar__tile abbr {
  transition: background-color 500ms;
}
.month-calendar .react-calendar__tile:hover abbr {
  background-color: #666 !important;
}

.month-calendar .react-calendar__month-view__days {
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

/* day cell */

.month-calendar .react-calendar__month-view__days .react-calendar__tile {
  background: white;
  color: #a4a4a4;
  cursor: pointer;
  font-size: 12px;
  border-left: 0;
  border-bottom: 0;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  max-width: none !important;
  flex-basis: unset !important;
  width: 14.285%;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
  padding: 3px;
}
.month-calendar .react-calendar__month-view__days .react-calendar__tile abbr {
  background: transparent;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 10px;
  z-index: 2;
}

.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range
  abbr,
.month-calendar.single-selection
  button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--hover
  abbr {
  color: #1d1d1d;
  background: #eee;
}
/* start/end of range */

.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--availability-range,
.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--availability-rangeStart {
  border-right: 0;
}

.month-calendar.muted-weekends
  .react-calendar__month-view__weekdays
  div:nth-child(6)
  abbr,
.month-calendar.muted-weekends
  .react-calendar__month-view__weekdays
  div:nth-child(7)
  abbr {
  color: #ccc;
}
.month-calendar.muted-weekends
  .react-calendar__month-view__days
  button.react-calendar__tile.react-calendar__month-view__days__day--weekend
  abbr {
  color: #ccc;
  background: #fff;
}

.month-calendar.muted-weekends
  button.react-calendar__tile.react-calendar__month-view__days__day--weekend:after {
  content: none;
}

.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--rangeStart
  abbr,
.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--rangeEnd
  abbr {
  background: #1d1d1d !important;
  color: white !important;
}
.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile::before {
  content: '';
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 68%;
}

@media only screen and (max-width: 1000px) {
  .month-calendar
    .react-calendar__month-view__days
    .react-calendar__tile::before {
    padding-top: 100%;
  }
}

.month-calendar .react-calendar__month-view__days .react-calendar__tile::after {
  content: '';
  display: table;
  clear: both;
}

/* availability */
.month-calendar .react-calendar__tile--availability-rangeStart {
  overflow: visible !important;
}
.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--unavailable,
.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--unavailable.react-calendar__tile--active {
  background: #ffc8ce !important;
  color: white;
}
.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--unavailable
  abbr {
  background: #ff2c41;
  color: white;
}
.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--available,
.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--available.react-calendar__tile--active {
  background: #d5f0e5 !important;
  color: white;
}
.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--available
  abbr {
  background: #32b87f;
  color: white;
}
.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--maybe-available,
.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--maybe-available.react-calendar__tile--active {
  background: #fbf0d5 !important;
  color: white;
}
.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--maybe-available
  abbr {
  background: #ecb22e;
  color: white;
}
.month-calendar .react-calendar__month-view__days .react-calendar__tile:hover {
  background: #fefefe;
}

.month-calendar .react-calendar__month-view__days .react-calendar__tile:after {
  content: ' ';
  height: 24px;
  width: 24px;
  border-radius: 23px;
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: 1;
  transition: background 250ms;
}
.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--availability-range:after,
.month-calendar
  .react-calendar__month-view__days
  button.react-calendar__tile.react-calendar__tile--range:after {
  width: 100%;
  left: 0;
  border-radius: 0;
}

.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--availability-rangeEnd:after,
.month-calendar
  .react-calendar__month-view__days
  button.react-calendar__tile.react-calendar__tile--rangeEnd:after {
  width: 100%;
  left: 0;
  border-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--availability-rangeStart:after,
.month-calendar
  .react-calendar__month-view__days
  button.react-calendar__tile.react-calendar__tile--rangeStart:after {
  right: 0;
  width: 25px;
  left: auto;
  border-radius: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.month-calendar
  .react-calendar__month-view__days
  button.react-calendar__tile.react-calendar__tile--rangeBothEnds:after {
  right: 1px;
  width: 24px;
  border-radius: 20px;
}

.month-calendar.single-selection
  .react-calendar__month-view__days
  button.react-calendar__tile.react-calendar__tile--hover:after {
  width: 100%;
  left: 0;
  border-radius: 0;
}

.month-calendar.single-selection
  .react-calendar__month-view__days
  button.react-calendar__tile.react-calendar__tile--hoverEnd:after {
  width: 100%;
  left: 0;
  border-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.month-calendar.single-selection
  .react-calendar__month-view__days
  button.react-calendar__tile.react-calendar__tile--hoverStart:after {
  right: 0;
  width: 25px;
  left: auto;
  border-radius: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.month-calendar.single-selection
  .react-calendar__month-view__days
  button.react-calendar__tile.react-calendar__tile--hover:after {
  background: rgba(200, 200, 200, 0.25);
}

.month-calendar
  .react-calendar__month-view__days
  button.react-calendar__tile.react-calendar__tile--range:after {
  background: #eee;
}

.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--available:after {
  background: #a4dfc5;
}

.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--maybe-available:after {
  background: #f7dda3;
}

.month-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--unavailable:after {
  background: #ff99a4;
}

/* hide weekends */
.month-calendar.react-calendar__hide-weekends
  .react-calendar__month-view__weekdays__weekday:nth-child(7n),
.month-calendar.react-calendar__hide-weekends
  .react-calendar__month-view__days__day:nth-child(7n),
.month-calendar.react-calendar__hide-weekends
  .react-calendar__month-view__weekdays__weekday:nth-child(6n),
.month-calendar.react-calendar__hide-weekends
  .react-calendar__month-view__days__day:nth-child(7n -1) {
  display: none;
}
.month-calendar.react-calendar__hide-weekends
  .react-calendar__month-view__weekdays__weekday,
.month-calendar.react-calendar__hide-weekends
  .react-calendar__tile.react-calendar__month-view__days__day {
  width: 20%;
  max-width: none !important;
  flex-basis: unset !important;
}
