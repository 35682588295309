.filter-calendar {
  width: 100% !important;
  border: none;
}
.filter-calendar button {
  appearance: none;
  background: none;
  outline: none;
  border: 0;
}

.filter-calendar .react-calendar__navigation__label {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  color: black;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  font-weight: 600;
}

.filter-calendar .react-calendar__navigation__prev2-button,
.filter-calendar .react-calendar__navigation__next2-button {
  display: none;
}

/* day of the week label */
.filter-calendar .react-calendar__month-view__weekdays__weekday {
  text-align: center;
  margin: 20px 0;
}
.filter-calendar abbr[title] {
  text-decoration: none;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #696969;
}

/* day cell */

.filter-calendar .react-calendar__month-view__days .react-calendar__tile {
  background: white;
  color: #a4a4a4;
  font-size: 12px;
  border-left: 0;
  border-bottom: 0;
  border-top: 0;
  border-right: 0;
  max-width: none !important;
  flex-basis: unset !important;
  width: 10.285%;
  margin: 2%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.filter-calendar
  .react-calendar__month-view__days
  .react-calendar__tile::before {
  content: '';
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
.filter-calendar .react-calendar__month-view {
  flex: 1;
}

.filter-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--now::before,
.filter-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--now::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
}

.filter-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--now::after {
  border-width: 0.2em;
  border-right-color: black;
  border-top-color: black;
}

.filter-calendar .react-calendar__viewContainer {
  display: flex;
  margin-bottom: 20px;
}

.filter-calendar
  .react-calendar__viewContainer
  .react-calendar__month-view:nth-child(1) {
  border-right: 1px solid #ddd;
  margin-right: 20px;
  padding-right: 20px;
}
.filter-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--active {
  background: #f1f1f1 !important;
  border-radius: 20px;
}
.filter-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--rangeStart,
.filter-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--rangeEnd {
  background: black !important;
  color: white !important;
}

.filter-calendar .react-calendar__month-view__days__day--neighboringMonth {
  display: none !important;
}

.filter-calendar
  .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend,
.filter-calendar .react-calendar__month-view__weekdays__weekday {
  opacity: 0.7;
}
