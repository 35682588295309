.mini-calendar {
  width: 100% !important;
  border: none;
}
.mini-calendar button {
  appearance: none;
  background: none;
  outline: none;
  border: 0;
}

.mini-calendar .react-calendar__navigation__label {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  color: black;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  font-weight: 600;
}

.mini-calendar .react-calendar__navigation__prev2-button,
.mini-calendar .react-calendar__navigation__next2-button {
  display: none;
}

/* day of the week label */
.mini-calendar .react-calendar__month-view__weekdays__weekday {
  text-align: center;
  margin: 20px 0;
}
.mini-calendar abbr[title] {
  text-decoration: none;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #696969;
}
.mini-calendar .react-calendar__month-view__days {
  border-left: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

/* day cell */
.mini-calendar .react-calendar__month-view__days .react-calendar__tile {
  background: white;
  color: #a4a4a4;
  font-size: 12px;
  border-left: 0;
  border-bottom: 0;
  border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  max-width: none !important;
  flex-basis: unset !important;
  width: 14.285%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.mini-calendar .react-calendar__month-view__days .react-calendar__tile::before {
  content: '';
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}

/* availability */
.mini-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--unavailable,
.mini-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--unavailable.react-calendar__tile--active {
  border-right: none;
  background: #f9c8ce !important;
  color: white;
}
.mini-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--available,
.mini-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--available.react-calendar__tile--active {
  border-right: none;
  background: #d5f0e5 !important;
  color: white;
}
.mini-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--maybe-available,
.mini-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--maybe-available.react-calendar__tile--active {
  border-right: none;
  background: #fbf0d5 !important;
  color: white;
}
.mini-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--active {
  border-right: none;
  background: white !important;
}
.mini-calendar
  .react-calendar__tile.react-calendar__tile--unavailable
  + .react-calendar__tile--unset,
.mini-calendar
  .react-calendar__tile.react-calendar__tile--available
  + .react-calendar__tile--unset,
.mini-calendar
  .react-calendar__tile.react-calendar__tile--maybe-available
  + .react-calendar__tile--unset {
  border-left: 1px solid #ddd;
}

/* hide weekends */
.mini-calendar.react-calendar__hide-weekends
  .react-calendar__month-view__weekdays__weekday:nth-child(7n),
.mini-calendar.react-calendar__hide-weekends
  .react-calendar__month-view__days__day:nth-child(7n),
.mini-calendar.react-calendar__hide-weekends
  .react-calendar__month-view__weekdays__weekday:nth-child(6n),
.mini-calendar.react-calendar__hide-weekends
  .react-calendar__month-view__days__day:nth-child(7n -1) {
  display: none;
}
.mini-calendar.react-calendar__hide-weekends
  .react-calendar__month-view__weekdays__weekday,
.mini-calendar.react-calendar__hide-weekends
  .react-calendar__tile.react-calendar__month-view__days__day {
  width: 20%;
  max-width: none !important;
  flex-basis: unset !important;
}

/* availability */
.mini-calendar .react-calendar__month-view__days .react-calendar__tile abbr {
  background: transparent;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  z-index: 2;
}
.mini-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--unavailable
  abbr {
  background: #ff2c41;
  color: white;
}
.mini-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--available
  abbr {
  background: #32b87f;
  color: white;
}
.mini-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--maybe-available
  abbr {
  background: #ecb22e;
  color: white;
}

.mini-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--now::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 2px;
  border-right-color: black;
  border-top-color: black;
}

.mini-calendar
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile-border
  abbr {
  border: black !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-radius: 100% !important;
}